@import 'components/global.scss';

.wrapper {

  display: flex;
  height: 100vh;
  padding: 10px;
}



.app {

  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  
}

.content {
  
  padding: $scale2;
  height: calc( 100% - 76px );
  width: 100%;
  overflow-y: auto;

  @media (max-width: $max-width){
    padding: $scale;
  }
  @media (max-width: $mobile){
    padding: 15px 10px;
  }

}